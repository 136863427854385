/*
 * @Author: your name
 * @Date: 2021-12-24 15:10:10
 * @LastEditTime: 2021-12-26 11:53:58
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \scm-admin\src\utils\acc.js
 */
// 浮点数加法运算
export function FloatAdd(num1, num2) {
  var r1, r2, m, c
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  c = Math.abs(r1 - r2)
  m = Math.pow(10, Math.max(r1, r2))
  if (c > 0) {
    var cm = Math.pow(10, c)
    if (r1 > r2) {
      num1 = Number(num1.toString().replace('.', ''))
      num2 = Number(num2.toString().replace('.', '')) * cm
    } else {
      num1 = Number(num1.toString().replace('.', '')) * cm
      num2 = Number(num2.toString().replace('.', ''))
    }
  } else {
    num1 = Number(num1.toString().replace('.', ''))
    num2 = Number(num2.toString().replace('.', ''))
  }
  return (num1 + num2) / m
  // return String((arg1 * m + arg2 * m) / m).indexOf('.') !== '-1' ? ((arg1 * m + arg2 * m) / m).toFixed(2) : (arg1 * m + arg2 * m) / m
}

// 浮点数减法运算
export function FloatSub(arg1, arg2) {
  var r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

// 浮点数乘法运算
export function FloatMul(arg1, arg2) {
  var m = 0; var s1 = arg1.toString(); var s2 = arg2.toString()
  // eslint-disable-next-line no-empty
  try { m += s1.split('.')[1].length } catch (e) {}
  // eslint-disable-next-line no-empty
  try { m += s2.split('.')[1].length } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

// 浮点数除法运算
export function FloatDiv(arg1, arg2) {
  let t1 = 0
  let t2 = 0
  let r1, r2
  // eslint-disable-next-line no-empty
  try { t1 = arg1.toString().split('.')[1].length } catch (e) { }
  // eslint-disable-next-line no-empty
  try { t2 = arg2.toString().split('.')[1].length } catch (e) { }
  // eslint-disable-next-line prefer-const
  r1 = Number(arg1.toString().replace('.', ''))
  // eslint-disable-next-line prefer-const
  r2 = Number(arg2.toString().replace('.', ''))
  const intDiv = r1 / r2
  const pow = Math.pow(10, t2 - t1)
  return FloatMul(intDiv, pow) // 这里用上面定义好的乘法运算
}
