<template>
  <div>
    <!-- 发票信息详情 -->
    <el-dialog title="发票信息" :visible.sync="dialogVisible" width="90%" center :close-on-click-modal="false" :before-close="closeDialog">

      <p style="text-align:right">
        <el-button type="primary" @click="exportClick()">下载</el-button>
      </p>
      <p style="justify-content: space-around;display: flex;align-items: center;">
        <span style="display: block;width: 15%;">供应商:{{ dialogData.vendorName }}</span>
        <span style="display: block;width: 15%;">结算主体:{{ dialogData.cargoOwnerName }}</span>
        <span style="display: block;width: 15%;">开票金额:{{ dialogData.registerAmount }}</span>
        <span style="display: block;width: 15%;">币种:{{ dialogData.currency }}</span>
        <span style="display: block;width: 15%;">税率(%):{{ dialogData.taxRatio }}</span>
        <span style="display: block;width: 15%;">发票状态:{{ getAuditTypeLabel('INVOICE_OPEN_STATUS',dialogData.status) }}</span>
        <el-button v-if="isNotApproved" :loading="invoiceLoading" type="primary" size="small" @click="batchDelete">批量删除</el-button>
      </p>
      <el-table
        ref="dialogFormTable"
        :data="dialogData.invoiceList"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="450px"
        @selection-change="handleInvoiceChange"
      >
        <el-table-column type="selection" width="50" />
        <el-table-column :label="$t('page.No')" width="55" type="index" align="center" />
        <el-table-column prop="invoiceCode" label="发票号" width="170" align="center" />
        <el-table-column prop="invoiceUntaxedAmount" label="发票未税金额" width="150" align="center" />
        <el-table-column prop="invoiceTaxAmount" label="发票税额" />
        <el-table-column prop="invoiceAmount" label="开票金额" />
        <el-table-column prop="taxRatio" label="税率(%)" />
        <el-table-column prop="openTime" label="开票日期" />
        <el-table-column prop="invoiceType" label="发票类型">
          <template slot-scope="scope">
            {{ getAuditTypeLabel('INVOICE_TYPE',scope.row.invoiceType) }}
          </template>
        </el-table-column>
        <el-table-column prop="invoiceStatus" label="发票状态">
          <template slot-scope="scope">
            {{ getAuditTypeLabel('INVOICE_STATUS',scope.row.invoiceStatus) }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="登记日期" />
        <el-table-column prop="createByName" label="登记人" />
        <el-table-column label="暂估勾稽" align="center" width="255">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.invoiceOutBills" :key="index">{{ item.payableAmount }}<br></span>
          </template>
        </el-table-column>
        <el-table-column v-if="isNotApproved" label="操作" align="center" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button v-if="scope.row.invoiceStatus === 2" type="text" @click="deleteDialogInvoice(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <p style="display: flex;">
        <span style="width: 56px;">备注：</span>
        <el-input v-model="remark" placeholder="请输入" />
      </p>
      <p style="text-align: center;">
        <el-button type="primary" :loading="saveLoading" :disabled="dialogData.invoiceList.length <= 0||!dialogData.invoiceList.some(item=>item.invoiceStatus+''==='2') " @click="save()">审核确认</el-button>
      </p>
      <!-- 已选暂估单 -->
      <p style="font-size: 18px;">已选暂估单</p>
      <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom:6px">
        <div style="display: flex;align-items: center;" class="total-amount">
          <span>单据金额：</span><span>{{ selectedBillAmount.totalPrice }}</span>
          <span>开票金额：</span><span>{{ selectedBillAmount.invoiceAmount }}</span>
          <span>税额：</span><span>{{ selectedBillAmount.valueAddedTaxAmount }}</span>
        </div>
        <el-button v-if="isNotApproved" :loading="selectedLoading" type="primary" size="small" @click="batchSelectedDelete">批量删除</el-button>
      </div>
      <el-table
        ref="selectedTable"
        :data="selectedBillList"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="450px"
        @selection-change="handleSelectedChange"
      >
        <el-table-column type="selection" width="50" :selectable="selectInit" />
        <el-table-column :label="$t('page.No')" type="index" align="center" />
        <el-table-column prop="estimationPayableCode" label="暂估应付单" min-width="170">
          <template slot-scope="scope">
            {{ scope.row.estimationPayableCode ? scope.row.estimationPayableCode:'一' }}
          </template>
        </el-table-column>
        <el-table-column prop="purchaseOrderCode" label="采购订单" width="160">
          <template slot-scope="scope">
            {{ scope.row.purchaseOrderCode ? scope.row.purchaseOrderCode:'一' }}
          </template>
        </el-table-column>
        <el-table-column prop="sourceCode" label="来源单据" width="180">
          <template slot-scope="scope">
            {{ scope.row.sourceCode ? scope.row.sourceCode:'一' }}
          </template>
        </el-table-column>

        <el-table-column prop="style" label="Style" width="150">
          <template slot-scope="scope">
            {{ scope.row.style ? scope.row.style:'一' }}
          </template>
        </el-table-column>
        <el-table-column prop="taxRatio" label="税率(%)">
          <template slot-scope="scope">
            {{ [null,undefined,''].includes(scope.row.taxRatio)? '一': scope.row.taxRatio }}
          </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="单据金额" />
        <el-table-column prop="invoiceAmount" label="开票金额" />
        <el-table-column prop="valueAddedTaxAmount" label="税额" />
        <el-table-column prop="shelfDate" label="上架日期">
          <template slot-scope="scope">
            {{ scope.row.shelfDate ? scope.row.shelfDate:'一' }}
          </template>
        </el-table-column>
        <el-table-column v-if="isNotApproved" prop="taxRatio" label="操作" width="60">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteSelectedInvoice(scope.row.estimationPayableCode)">删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <!-- 待勾稽暂估单 -->
      <div v-if="isNotApproved">
        <p style="font-size: 18px;">待勾稽暂估单</p>
        <el-form ref="form" :model="form" label-width="100px" style="display: flex">
          <div class="el-lt" style="width: 85%">
            <el-row :span="24" class="row-input">
              <el-col :span="10">
                <el-form-item label="上架日期">
                  <el-date-picker
                    v-model="form.shelfDate"
                    class="date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="暂估应付单号" :class="$i18n.locale">
                  <el-input v-model="form.estimationPayableCode" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item label="采购单号" :class="$i18n.locale">
                  <el-input v-model="form.purchaseOrderCode" placeholder="请输入" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row class="el-rt" style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" :loading="queryLoading" @click="_getNoRegisterOutBillViewListByPage()">
                {{ $t("page.search") }}
              </el-button>
              <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
            </el-col>
          </el-row>
        </el-form>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom:6px">
          <div style="display: flex;align-items: center;" class="total-amount">
            <span>单据金额：</span><span>{{ unSelectedBillAmount.totalPrice }}</span>
            <span>开票金额：</span><span>{{ unSelectedBillAmount.invoiceAmount }}</span>
            <span>税额：</span><span>{{ unSelectedBillAmount.valueAddedTaxAmount }}</span>
          </div>
          <el-button v-if="isNotApproved" :loading="unselectedLoading" type="primary" size="small" @click="batchAdd">批量添加</el-button>
        </div>
        <el-table
          ref="unselectedTable"
          :data="unSelectedBillList"
          :header-cell-style="{ background: '#fafafa' }"
          tooltip-effect="dark"
          max-height="450px"
          @selection-change="handleUnselectedChange"
        >
          <el-table-column type="selection" width="50" />
          <el-table-column :label="$t('page.No')" type="index" align="center" />
          <el-table-column prop="estimationPayableCode" label="暂估应付单" min-width="170">
            <template slot-scope="scope">
              {{ scope.row.estimationPayableCode ? scope.row.estimationPayableCode:'一' }}
            </template>
          </el-table-column>
          <el-table-column prop="purchaseOrderCode" label="采购订单" width="160">
            <template slot-scope="scope">
              {{ scope.row.purchaseOrderCode ? scope.row.purchaseOrderCode:'一' }}
            </template>
          </el-table-column>
          <el-table-column prop="sourceCode" label="来源单据" width="180">
            <template slot-scope="scope">
              {{ scope.row.sourceCode ? scope.row.sourceCode:'一' }}
            </template>
          </el-table-column>

          <el-table-column prop="paymentTypeI18" label="付款类型" width="100">
            <template slot-scope="scope">
              {{ scope.row.paymentTypeI18 ? scope.row.paymentTypeI18:'一' }}
            </template>
          </el-table-column>
          <el-table-column prop="currency" label="币种" width="60">
            <template slot-scope="scope">
              {{ scope.row.currency ? scope.row.currency:'一' }}
            </template>
          </el-table-column>
          <el-table-column prop="taxRatio" label="税率(%)">
            <template slot-scope="scope">
              {{ [null,undefined,''].includes(scope.row.taxRatio)? '一': scope.row.taxRatio }}
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="单据金额" />
          <el-table-column prop="invoiceAmount" label="开票金额" />
          <el-table-column prop="valueAddedTaxAmount" label="税额" />
          <el-table-column prop="shelfDate" label="上架日期" width="120">
            <template slot-scope="scope">
              {{ scope.row.shelfDate ? scope.row.shelfDate:'一' }}
            </template>
          </el-table-column>
          <el-table-column prop="taxRatio" label="操作" width="60">
            <template slot-scope="scope">
              <el-button type="text" @click="add(scope.row.estimationPayableCode)">添加</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <Paging :pager="pagers" end @pagination="pagersUpdate" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import {
  invoiceDelete,
  queryInvoiceOpen,
  invoiceOpenAudit,
  exportHaveRegisterOutBillData,
  getHaveRegisterOutBillViewList,
  getNoRegisterOutBillViewListByPage,
  batchDeleteInvoice,
  batchDeleteHaveRegisterOutBill,
  batchAddNoRegisterOutBill
} from '@/api/vendorPurchase-api.js'
import { handleDownload } from '@/utils/index'
import { FloatAdd } from '@/utils/acc.js'
export default {
  components: { Paging },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      remark: '',
      selectedBillList: [],
      unSelectedBillList: [],
      dialogData: {
        invoiceList: []
      },
      form: {},
      saveLoading: false,
      queryLoading: false,
      tatolPairs: '',
      pagers: {
        total: 0,
        current: 1,
        size: 20
      },
      multiInvoiceList: [],
      multiSelectedList: [],
      multiUnselectedList: [],
      selectedBillAmount: {
        totalPrice: 0,
        invoiceAmount: 0,
        valueAddedTaxAmount: 0
      },
      unSelectedBillAmount: {
        totalPrice: 0,
        invoiceAmount: 0,
        valueAddedTaxAmount: 0
      },
      invoiceLoading: false,
      selectedLoading: false,
      unselectedLoading: false
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isNotApproved() {
      return this.rowData.status !== 3
    }
  },
  watch: {
    async 'dialogVisible'(val) {
      if (val) {
        await this._queryInvoiceOpen()
        await this._getHaveRegisterOutBillViewList()
        if (this.isNotApproved) {
          this.$refs.selectedTable.toggleAllSelection()
        } else {
          this.selectedBillList.forEach((item) => {
            this.$refs.selectedTable.toggleRowSelection(item, true)
          })
        }
        this.form = {}
        this.isNotApproved && this._getNoRegisterOutBillViewListByPage()
      }
    }
  },
  methods: {
    selectInit() {
      return this.isNotApproved
    },
    handleInvoiceChange(val) {
      this.multiInvoiceList = val
    },
    handleSelectedChange(val) {
      this.multiSelectedList = val
      this.selectedBillAmount.totalPrice = 0
      this.selectedBillAmount.invoiceAmount = 0
      this.selectedBillAmount.valueAddedTaxAmount = 0
      this.multiSelectedList.forEach((select) => {
        this.selectedBillAmount.totalPrice = FloatAdd(this.selectedBillAmount.totalPrice, select.totalPrice)
        this.selectedBillAmount.invoiceAmount = FloatAdd(this.selectedBillAmount.invoiceAmount, select.invoiceAmount)
        this.selectedBillAmount.valueAddedTaxAmount = FloatAdd(this.selectedBillAmount.valueAddedTaxAmount, select.valueAddedTaxAmount)
      })
    },
    handleUnselectedChange(val) {
      this.multiUnselectedList = val
      this.unSelectedBillAmount.totalPrice = 0
      this.unSelectedBillAmount.invoiceAmount = 0
      this.unSelectedBillAmount.valueAddedTaxAmount = 0
      this.multiUnselectedList.forEach((select) => {
        this.unSelectedBillAmount.totalPrice = FloatAdd(this.unSelectedBillAmount.totalPrice, select.totalPrice)
        this.unSelectedBillAmount.invoiceAmount = FloatAdd(this.unSelectedBillAmount.invoiceAmount, select.invoiceAmount)
        this.unSelectedBillAmount.valueAddedTaxAmount = FloatAdd(this.unSelectedBillAmount.valueAddedTaxAmount, select.valueAddedTaxAmount)
      })
    },
    // 发票信息批量删除
    async batchDelete() {
      if (this.multiInvoiceList.length === 0) {
        this.$message({
          message: '请选择需要删除的发票',
          type: 'warning'
        })
        return
      }
      this.invoiceLoading = true
      try {
        const { code, msg } = await batchDeleteInvoice({
          invoiceCodeList: this.multiInvoiceList.map(v => v.invoiceCode),
          invoiceOpenId: this.rowData.id
        })
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this._queryInvoiceOpen()
        }
      } finally {
        this.invoiceLoading = false
      }
    },
    // 已选暂估单批量删除
    batchSelectedDelete() {
      const codeList = this.multiSelectedList.map((v) => v.estimationPayableCode)
      if (codeList.length === 0) {
        this.$message.warning('请选择批量操作的数据！')
        return
      }
      this.selectedLoading = true
      this._batchDeleteHaveRegisterOutBill(codeList)
    },
    deleteSelectedInvoice(estimationPayableCode) {
      this._batchDeleteHaveRegisterOutBill([estimationPayableCode])
    },
    // 删除已选暂估单
    async _batchDeleteHaveRegisterOutBill(estimationCodeList) {
      try {
        const { code, msg } = await batchDeleteHaveRegisterOutBill({
          estimationCodeList,
          invoiceOpenId: this.rowData.id
        })
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.multiSelectedList = []
          this._getHaveRegisterOutBillViewList()
          this._getNoRegisterOutBillViewListByPage()
        }
      } finally {
        this.selectedLoading = false
      }
    },
    batchAdd() {
      const codeList = this.multiUnselectedList.map((v) => v.estimationPayableCode)
      if (codeList.length === 0) {
        this.$message.warning('请选择批量操作的数据！')
        return
      }
      this.unselectedLoading = true
      this._batchAddNoRegisterOutBill(codeList)
    },
    add(estimationPayableCode) {
      this._batchAddNoRegisterOutBill([estimationPayableCode])
    },
    resetClick() {
      this.form = this.$options.data().form
      this.$refs.unselectedTable.clearSelection()
      this.pagers.current = 1
      this._getNoRegisterOutBillViewListByPage()
    },
    // 添加未勾稽暂估单
    async _batchAddNoRegisterOutBill(estimationCodeList) {
      try {
        const { code, msg } = await batchAddNoRegisterOutBill({
          estimationCodeList,
          invoiceOpenId: this.rowData.id
        })
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.multiSelectedList = []
          this._getHaveRegisterOutBillViewList()
          this.pagers.current = 1
          this._getNoRegisterOutBillViewListByPage()
        }
      } finally {
        this.unselectedLoading = false
      }
    },
    // 下载导出
    async exportClick() {
      const res = await exportHaveRegisterOutBillData({
        invoiceOpenId: this.rowData.id
      })
      handleDownload(res, '已选暂估单信息' + '.xlsx')
    },
    // 发票详情删除
    async deleteDialogInvoice(row) {
      invoiceDelete({ id: row.id }).then(response => {
        this.$notify({
          title: response.msg,
          message: response.msg,
          type: 'success'
        })
        this._queryInvoiceOpen()
      })
    },
    // 发票详情
    async _queryInvoiceOpen() {
      const { datas } = await queryInvoiceOpen(this.rowData.id)
      this.dialogData = datas
      const reg = /[,，]/g
      this.dialogData.invoiceList.map(e => {
        e.invoiceOutBills?.map(i => {
          i.payableAmount = (i.estimationPayableCode + '-' + '(' + i.estimationPayableAmount + ')').replace(reg, '$&\r\n')
        })
      })
      this.remark = datas.remark ? datas.remark : ''
    },
    // 获取已选暂估单
    async _getHaveRegisterOutBillViewList() {
      const { datas } = await getHaveRegisterOutBillViewList({ invoiceOpenId: this.rowData.id })
      this.selectedBillList = datas
    },
    // 获取未勾稽暂估单
    async _getNoRegisterOutBillViewListByPage() {
      this.queryLoading = true
      const { cargoOwnerCode, currency, taxRatio, vendorId } = this.dialogData
      const { estimationPayableCode, purchaseOrderCode, shelfDate } = this.form
      const [shelfDateStart, shelfDateEnd] = shelfDate || [null, null]
      try {
        const { datas: { records, pager }} = await getNoRegisterOutBillViewListByPage(
          Object.assign({}, this.pagers, {
            cargoOwnerCode,
            currency,
            estimationPayableCode,
            purchaseOrderCode,
            taxRatio,
            vendorId,
            shelfDateStart,
            shelfDateEnd
          }))
        this.unSelectedBillList = records
        this.pagers = pager
      } finally {
        this.queryLoading = false
      }
    },
    // 未勾稽暂估单分页方法
    pagersUpdate(val) {
      this.pagers = val
      this._getNoRegisterOutBillViewListByPage()
    },
    // 审核确认
    save() {
      const same = this.dialogData.invoiceList.every(e => e.invoiceAmount > 0)
      const isSame = this.dialogData.invoiceList.every(e => e.invoiceAmount < 0)
      if (same === false && isSame === false) {
        this.$message({
          message: '发票信息不正确',
          type: 'warning'
        })
        return
      } else {
        this.$emit('audit', { data: this.dialogData, remark: this.remark })
      }

      // invoiceAmount    taxRegisterAmount
      // let invoiceAmount = 0
      // let invoiceTaxAmount = 0
      // for (let i = 0; i < this.dialogData.invoiceList.length; i++) {
      //   if (this.dialogData.invoiceList[i].invoiceStatus !== 1) {
      //     invoiceAmount += Number(this.dialogData.invoiceList[i].invoiceAmount)
      //     invoiceTaxAmount += Number(this.dialogData.invoiceList[i].invoiceTaxAmount)
      //   }
      // }
      // // 四舍五入
      // invoiceAmount = Math.round((invoiceAmount + Number.EPSILON) * 100) / 100
      // invoiceTaxAmount = Math.round((invoiceTaxAmount + Number.EPSILON) * 100) / 100
      // console.log(invoiceAmount)
      // console.log(invoiceTaxAmount)
      // if (invoiceAmount !== this.dialogData.registerAmount || invoiceTaxAmount !== this.dialogData.taxRegisterAmount) {
      //   this.$confirm('发票信息与开票信息不一致，确认提交审核吗？', this.$t('page.Prompt'), {
      //     confirmButtonText: this.$t('page.sure'),
      //     cancelButtonText: this.$t('title.cancel')
      //   }).then(() => {
      //     this.dialogData.remark = this.remark
      //     // this._invoiceOpenAudit(this.dialogData)
      //   })
      // } else {
      //   this.dialogData.remark = this.remark
      //   // this._invoiceOpenAudit(this.dialogData)
      // }

      // this.dialogData.remark = this.remark
      // this._invoiceOpenAudit(this.dialogData)
    },
    async _invoiceOpenAudit(data) {
      try {
        this.saveLoading = true
        const { code, msg } = await invoiceOpenAudit(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.saveLoading = false
        this.dialogVisible = false
        this.$emit('refresh')
      } catch (err) {
        console.log(err)
        this.saveLoading = false
      } finally {
        this.saveLoading = false
        this.dialogVisible = false
        this.$emit('refresh')
      }
    },

    getSummaries(param) { // THIRD_PARTY  第三方仓   OWN自有仓  PLATFORM平台
      const { columns } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('page.total')
          return
        }
        if (column.property === 'reconcileAmount') { // 收货箱数
          sums[index] = this.tatolPairs
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    closeDialog() {
      this.$emit('refresh')
      this.dialogVisible = false
      this.remark = ''
    }
  }
}
</script>
<style scoped lang="scss">
.total-amount {
  & > span:nth-child(2n+1) {
    width: 100px;
    text-align: right;
  }
  & > span:nth-child(2n) {
    display: block;
    height: 30px;
    line-height: 30px;
    width: 180px;
    overflow: hidden;
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    margin-right: 10px;
  }
}
</style>
