<template>
  <div>
    <el-form ref="form" :model="form" label-width="150px" style="display: flex" :rules="rules">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">

          <el-col :span="16">
            <el-form-item label="登记日期">
              <el-date-picker
                v-model="form.value1"
                class="date"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale" prop="vendorId">
              <el-select v-model="form.vendorId" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option v-for="item in vendorOptions" :key="item.id" :label="item.vendorName" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结算主体" :class="$i18n.locale" prop="cargoOwnerCode">
              <el-select v-model="form.cargoOwnerCode" style="width:100%;" value-key="id" clearable filterable>
                <el-option
                  v-for="item in cargoOwnerNameList"
                  :key="item.cargoOwnerName"
                  :label="item.cargoOwnerName"
                  :value="item.cargoOwnerCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发票号" :class="$i18n.locale">
              <el-input v-model="form.invoiceCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开票状态" :class="$i18n.locale">
              <el-select v-model="form.status" clearable filterable>
                <el-option
                  v-for="item in _getAllCommodityDict('INVOICE_OPEN_STATUS')"
                  :key="item.val"
                  :label="item.label"
                  :value="item.val"
                />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <el-button
              v-permission="'invoiceInfoReg'"
              type="primary"
              @click="showPayReg()"
            >开票信息登记</el-button>
            <el-upload
              ref="uploadForm"
              action=""
              class="el-button padding-none"
              :http-request="uploadFile"
              :show-file-list="false"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="fileChange"
            >
              <el-button type="primary"> 导入发票</el-button>
            </el-upload>
            <el-button type="primary" @click="downTemplate('INVOICE_KEY')">模板下载</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width: 25%">
        <el-col class="row-center">
          <el-button type="primary" :loading="queryLoading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="invoiceColumns"
      :page-obj="pager"
    >
      <el-table-column slot="collectMonth" label="到货月份">
        <template slot-scope="scope">
          {{ scope.row.collectMonth && scope.row.collectMonth.substring(0,7) }}
        </template>
      </el-table-column>

      <el-table-column slot="operate" label="开票状态">
        <template slot-scope="scope">
          {{ getAuditTypeLabel('INVOICE_OPEN_STATUS',scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column slot="operateName" label="操作人">
        <template slot-scope="scope">
          {{ scope.row.createByName && (scope.row.createByName !== null || scope.row.createByName !==undefined) ? scope.row.createByName : scope.row.modifyByName }}
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <!-- 请款审批状态为审批通过允许操作，校验不通过给出提示 -->
          <el-button
            type="text"
            @click="handleShow(scope.row)"
          >查看</el-button>
          <el-button
            v-if="scope.row.status != 3"
            type="text"
            @click="deleteInvoice(scope.row.id)"
          >删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <!-- 发票信息详情 -->
    <detailDialog v-model="dialogVisible" :row-data="rowData" @refresh="queryClick" @audit="openAudit" />
    <!-- 导入发票信息详情 -->
    <el-dialog title="导入发票信息" :visible.sync="dialogVisibleImport" width="90%" :before-close="closeDialogImport" :close-on-click-modal="false">
      <el-table
        ref="dialogFormTable"
        :data="importData"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="450px"
        :row-style="tableAddClass"
      >
        <el-table-column :label="$t('page.No')" type="index" align="center" />
        <el-table-column prop="invoiceCode" label="发票号" min-width="150" />
        <el-table-column prop="errorMsg" label="错误信息" align="center" width="165">
          <template slot-scope="scope">
            <span v-for="(item,index) in scope.row.errorMsg" :key="index">{{ item }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currency" label="币种" width="100" />
        <el-table-column prop="openTime" label="开票日期" width="120" />
        <el-table-column prop="invoiceTaxAmount" label="发票税额" width="120" />
        <el-table-column prop="vendorFullName" label="供应商" align="center" width="170" />
        <el-table-column prop="invoiceAmount" label="开票金额" />
        <el-table-column prop="invoiceType" label="发票类型">
          <template slot-scope="scope">
            {{ getAuditTypeLabel('INVOICE_TYPE',scope.row.invoiceType) }}
          </template>
        </el-table-column>
        <el-table-column prop="collectMonth" label="到货月份">
          <template slot-scope="scope">
            {{ scope.row.collectMonth && scope.row.collectMonth.substring(0,7) }}
          </template>
        </el-table-column>
        <el-table-column prop="taxRatio" label="税率(%)" />
        <el-table-column prop="cargoOwnerFullName" label="结算主体" align="center" width="170" />

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="commitLoading" :disabled="commitIsTrue" @click="commit()">确认提交</el-button>
      </span>

    </el-dialog>
    <!-- 审核确认框 -->
    <el-dialog title="发票审核" center :visible.sync="dialogVisibleInvoiceReview" width="90%" :close-on-click-modal="false">
      <div class="mb-3" style="display: flex;justify-content: space-around;">
        <div style="width:40%">
          <p>发票信息:</p>
          <ul>
            <li v-for="(item,index) in reviewData.invoiceList" :key="item.id" :class="{'invoiceActive':index === isInd}" @click="handleInvoice(index,item)"><span>{{ item.invoiceCode }}{{ '('+item.invoiceAmount+')' }}</span></li>
          </ul>
        </div>
        <div style="width:40%">
          <p>暂估信息:</p>
          <ul>
            <li v-for="(items,ind) in reviewData.outBillList" :key="items.id" :class="{'active':outBillIdList.includes(items.id)}" @click="handlePayable(items.id,items,ind)"><span><i style="font-weight: 800;">{{ outBillSort(items.id)?outBillSort(items.id)+'.':'' }}</i> {{ items.estimationPayableCode }} {{ '('+items.totalPrice+')' }}</span></li>
          </ul>
        </div>
      </div>
      <el-row :span="24" class="mb-3">
        <el-col :span="12" style="text-align: center;"><el-button type="primary" :loading="ClearLoading" @click="handleClear">清空</el-button></el-col>
        <el-col :span="12" style="text-align: center;"><el-button type="primary" :loading="AuditLoading" :disabled="myInvoiceList.length === 0 || myOutBillIdList.length === 0" @click="handleAudit">勾稽</el-button></el-col>
      </el-row>
      <el-table
        ref="dialogFormTable"
        :data="reviewData.invoiceOutBillList"
        :header-cell-style="{ background: '#fafafa' }"
        tooltip-effect="dark"
        max-height="450px"
        show-summary
        :summary-method="getTotal"
      >
        <el-table-column prop="invoiceCode" label="发票编号" />
        <el-table-column prop="invoiceAmount" label="发票金额" />
        <el-table-column prop="invoiceType" label="暂估勾稽" min-width="200">
          <template slot-scope="scope">
            <div v-for="items in scope.row.outBillList" :key="items.id">
              <p>{{ items.estimationPayableCode }} {{ '('+items.totalPrice+')' }} </p>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="InvoiceLoading" :disabled=" reviewData.invoiceOutBillList === null || reviewData.invoiceOutBillList.length < 0 " @click="handleConfirm()">确 认</el-button>
      </span>

    </el-dialog>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { downloads } from '@/utils'
import Table from '@/components/Table'
import { queryVendorList, findDownloadUrl } from '@/api/scm-api'
import Paging from '@/components/Pagination'
import { invoiceColumns, expandColumns, ticketColumns } from '@/constant/tablecolumns'
import { getcargoownerList } from '@/api/scm-api'
import { invoiceOpenConfirm, invoiceOpenClear, invoiceOpenAuto, invoiceManualMatch, invoiceImport, invoicePreview, deleteInvoiceOpen, invoiceOpenPage } from '@/api/vendorPurchase-api.js'
import { deepClone } from '@/utils'
import detailDialog from './detailDialog/index.vue'
export default {
  components: { Table, Paging, detailDialog },
  mixins: [commodityInfoDict],
  data() {
    return {
      InvoiceLoading: false,
      outBillIdList: [], // 暂估单选中id
      commitIsTrue: false,
      AuditLoading: false,
      ClearLoading: false,
      dialogVisibleInvoiceReview: false,
      ConfirmLoading: false,
      reviewData: {
        invoiceList: [],
        outBillList: [],
        invoiceOutBillList: []
      },
      commitLoading: false,
      importData: [],
      importDialog: false,
      remark: '',
      rowData: {},
      dialogVisibleImport: false,
      dialogForm: [],
      dialogAuditVisible: false,
      dialogVisible: false,
      pager: {
        total: 0,
        current: 1,
        size: 20
      },
      invoiceColumns,
      expandColumns,
      ticketColumns,
      tableData: [

      ],
      queryLoading: false,
      vendorOptions: [],
      showRow: false,
      form: {
        value1: []
      },
      row: {
        labelArr: [],
        columns1: []
      },
      dialogData: {
        invoiceList: []
      },
      type: '',
      cargoOwnerNameList: [],
      rules: {
        vendorId: { required: true, message: '必填', trigger: 'change' }
      },
      fileList: [],
      isInd: '',
      myInvoiceList: [],
      myOutBillIdList: []
    }
  },
  computed: {
    queryPageParams() {
      const obj = deepClone(this.form)
      const { value1: [invoiceDateStart, invoiceDateEnd] } = obj
      delete obj.value1
      return Object.assign({}, obj, this.pager, { invoiceDateStart, invoiceDateEnd },)
    }
  },
  watch: {
    'dialogVisibleInvoiceReview'(val) {
      if (!val) {
        this.dialogVisibleInvoiceReview = false
        this.isInd = ''
        this.outBillIdList = []
        this.myOutBillIdList = []
        this.myInvoiceList = []
      }
    }
  },
  mounted() {
    this._queryVendorList()
    this.queryClick()
    this._getcargoownerList()
  },
  methods: {
    // 整行标红
    tableAddClass({ row, rowIndex }) {
      if (row.errorMsg && row.errorMsg.length > 0) {
        this.commitIsTrue = true
        return { 'color': 'red' }
      } else {
        this.commitIsTrue = false
        return {}
      }
    },
    async downTemplate(key) {
      console.log(key)
      const { datas } = await findDownloadUrl(key)
      downloads(datas)
    },
    // 查看
    handleShow(row) {
      this.rowData = row
      this.dialogVisible = true
    },
    deleteInvoice(row) {
      this.$confirm('确定要删除吗', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel')
      }).then(() => {
        this._deleteInvoiceOpen(row)
      })
    },
    closeDialogImport() {
      this.dialogVisibleImport = false
      this.remark = ''
      this.queryClick()
    },
    // 删除列表信息接口调用
    async _deleteInvoiceOpen(row) {
      deleteInvoiceOpen(row)
        .then(response => {
          this.$notify({
            title: response.msg,
            message: response.msg,
            type: 'success'
          })
          this.queryClick()
        })
        .catch(error => {
          console.log(error)
          this.queryClick()
        })
    },

    customStyle(flag) {
      if ([2, 3, 4].includes(flag)) {
        return {
          color: '#606266',
          cursor: 'default '
        }
      }
    },
    // 获取结算主题信息
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    handleInvoice(index, item) {
      if (this.isInd === index) {
        this.isInd = ''
        this.myInvoiceList = []
      } else {
        this.isInd = index
        this.myInvoiceList = [item]
      }
    },
    handlePayable(rowId, item, ind) {
      const index = this.outBillIdList.findIndex(e => e === rowId)
      console.log(ind)
      if (index === -1) {
        this.outBillIdList.push(rowId)
        this.myOutBillIdList.push(item)
      } else {
        this.outBillIdList.splice(index, 1)
        this.myOutBillIdList.splice(index, 1)
      }
    },
    // 排序
    outBillSort(id) {
      const index = this.outBillIdList.findIndex(e => e === id)
      return index === -1 ? '' : index + 1
    },
    async openAudit(params) {
      this.remark = params.remark
      this.dialogData = params.data
      const { code, msg, datas } = await invoiceOpenAuto({ invoiceOpenId: this.rowData.id })
      this.reviewData = datas
      if (code === 0) {
        this.dialogVisibleInvoiceReview = true
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
      }
    },
    // 勾稽
    handleAudit() {
      const isLastInvoice = this.reviewData.invoiceList.length === 1
      // if (this.reviewData.invoiceList.length === 1) {
      //   isLastInvoice = true
      // } else {
      //   isLastInvoice = false
      // }
      const data = Object.assign({}, { invoiceList: this.myInvoiceList, outBillList: this.myOutBillIdList, isLastInvoice: isLastInvoice })
      this._invoiceManualMatch(data)
    },
    async _invoiceManualMatch(data) {
      try {
        this.AuditLoading = true
        const { code, msg, datas } = await invoiceManualMatch(data)
        if (datas.invoiceList && datas.invoiceList.length > 0) {
          this.reviewData.invoiceList = [this.reviewData.invoiceList, ...datas.invoiceList]
          this.isInd = '' // 清空发票信息的选中
        } else {
          const index = this.reviewData.invoiceList.findIndex(e => e.invoiceCode === this.myInvoiceList[0].invoiceCode)
          if (index !== -1) {
            this.reviewData.invoiceList.splice(index, 1) // 删已选择发票信息
          }
          this.isInd = ''
        }
        console.log(this.myOutBillIdList)
        console.log(datas.outBillList)
        // 判断获取合并/删除暂估单信息
        if (datas.outBillList && datas.outBillList.length > 0) {
          const ids = []
          datas.invoiceOutBillList.map(e => {
            e.outBillList.map(v => {
              const index = this.myOutBillIdList.findIndex(item => { return item.estimationPayableCode === v.estimationPayableCode && item.totalPrice === v.totalPrice })
              if (index !== -1) {
                ids.push(this.myOutBillIdList[index].id)
              } else {
                datas.outBillList.map(e => {
                  this.reviewData.outBillList.map(items => {
                    if (e.estimationPayableCode === items.estimationPayableCode && e.purchaseOrderCode === items.purchaseOrderCode) {
                      this.$set(items, 'totalPrice', e.totalPrice)
                    }
                  })
                })
              }
            })
          })
          console.log(ids)
          ids.map(e => {
            const index = this.reviewData.outBillList.findIndex(v => v.id === e)
            if (index !== -1) {
              this.reviewData.outBillList.splice(index, 1)
            } else {
              datas.outBillList.map(e => {
                this.reviewData.outBillList.map(items => {
                  if (e.estimationPayableCode === items.estimationPayableCode && e.purchaseOrderCode === items.purchaseOrderCode) {
                    this.$set(items, 'totalPrice', e.totalPrice)
                  }
                })
              })
            }
          })

          // console.log(this.myOutBillIdList)
          // this.myOutBillIdList.map(v => { // 拿到选中的数组判断
          //   console.log(v)
          //   datas.invoiceOutBillList.map(item => {
          //     const index = item.outBillList.findIndex(e => e.estimationPayableCode === v.estimationPayableCode && e.totalPrice === v.totalPrice)
          //     console.log(index)
          //     if (index !== -1) {
          //       this.reviewData.outBillList.splice(index, 1)
          //     } else {
          //       datas.outBillList.map(e => {
          //         this.reviewData.outBillList.map(items => {
          //           if (e.estimationPayableCode === items.estimationPayableCode && e.purchaseOrderCode === items.purchaseOrderCode) {
          //             this.$set(item, 'totalPrice', e.totalPrice)
          //           }
          //         })
          //       })
          //     }
          //   })
          // })
          // console.log(this.reviewData.outBillList)
          // datas.outBillList.map(e => {
          //   this.reviewData.outBillList.map(item => {
          //     if (e.estimationPayableCode === item.estimationPayableCode && e.purchaseOrderCode === item.purchaseOrderCode) {
          //       this.$set(item, 'totalPrice', e.totalPrice)
          //     }
          //   })
          // })
          // this.myOutBillIdList.map(v => {
          //   const index = datas.outBillList.findIndex(e => e.estimationPayableCode === v.estimationPayableCode)
          //   if (index !== -1) {
          //     this.reviewData.outBillList.splice(index, 1)
          //   }
          // })

          this.outBillIdList = []
          this.myOutBillIdList = []
        } else {
          this.myOutBillIdList.map(v => {
            const index = this.reviewData.outBillList.findIndex(e => e.estimationPayableCode === v.estimationPayableCode)
            if (index !== -1) {
              this.reviewData.outBillList.splice(index, 1)
            }
          })
          this.myOutBillIdList = []
        }
        // 判断合并已勾稽发票信息
        if (datas.invoiceOutBillList && datas.invoiceOutBillList.length > 0) {
          if (this.reviewData.invoiceOutBillList === null) {
            this.reviewData.invoiceOutBillList = []
          }
          this.reviewData.invoiceOutBillList = [...this.reviewData.invoiceOutBillList, ...datas.invoiceOutBillList]
        }
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.AuditLoading = false
        this.myInvoiceList = []
        this.myOutBillIdList = []
        // this.dialogVisibleImport = false
        this.queryClick()
      } catch (err) {
        console.log(err)
        this.AuditLoading = false
      } finally {
        this.AuditLoading = false
        this.queryClick()
      }
    },
    // 清空
    async  handleClear() {
      console.log(this.dialogData)
      try {
        this.ClearLoading = true
        const { code, msg, datas } = await invoiceOpenClear({ invoiceOpenId: this.dialogData.id })
        this.reviewData = datas
        this.isInd = ''
        this.outBillIdList = []
        this.myOutBillIdList = []
        this.myInvoiceList = []
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.ClearLoading = false
      } catch (err) {
        console.log(err)
        this.ClearLoading = false
      } finally {
        this.ClearLoading = false
      }
    },
    handleConfirm() {
      // invoiceAmount    taxRegisterAmount
      let invoiceOutAmount = 0 // 已勾稽金额
      let invoiceOutTaxAmount = 0// 已勾稽税额
      let invoiceAmount = 0 // 发票金额
      let invoiceTaxAmount = 0 // 发票税额

      // 已勾稽
      for (let i = 0; i < this.reviewData.invoiceOutBillList.length; i++) {
        const e = this.reviewData.invoiceOutBillList[i]
        invoiceOutAmount += Number(e.invoiceAmount)
        invoiceOutTaxAmount += Number(e.invoiceTaxAmount)
      }
      // 发票
      for (let i = 0; i < this.reviewData.invoiceList.length; i++) {
        const e = this.reviewData.invoiceList[i]
        invoiceAmount += Number(e.invoiceAmount)
        invoiceTaxAmount += Number(e.invoiceTaxAmount)
      }
      // 金额
      invoiceAmount = invoiceAmount + invoiceOutAmount
      // 税额
      invoiceTaxAmount = invoiceTaxAmount + invoiceOutTaxAmount
      // 四舍五入
      invoiceAmount = Math.round((invoiceAmount + Number.EPSILON) * 100) / 100
      invoiceTaxAmount = Math.round((invoiceTaxAmount + Number.EPSILON) * 100) / 100
      this.reviewData.remark = this.remark
      if (invoiceAmount !== this.dialogData.registerAmount || invoiceTaxAmount !== this.dialogData.taxRegisterAmount) {
        this.$confirm('发票信息与开票信息不一致，确认提交审核吗？', this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure'),
          cancelButtonText: this.$t('title.cancel')
        }).then(() => {
          this._invoiceOpenConfirm(this.reviewData)
        })
      } else {
        this._invoiceOpenConfirm(this.reviewData)
      }
    },
    async _invoiceOpenConfirm(data) {
      try {
        this.InvoiceLoading = true
        const { code, msg } = await invoiceOpenConfirm(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.ConfirmLoading = false
          this.dialogVisibleInvoiceReview = false
          this.dialogVisible = false
          this.queryClick()
          this.InvoiceLoading = false
        }
      } catch (err) {
        console.log(err)
        this.InvoiceLoading = false
      } finally {
        this.InvoiceLoading = false
      }
    },
    commit() {
      this._invoiceImport(this.importData)
    },
    async _invoiceImport(data) {
      try {
        this.commitLoading = true
        const { code, msg } = await invoiceImport(data)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.commitLoading = false
        this.dialogVisibleImport = false
        this.queryClick()
      } catch (err) {
        console.log(err)
        this.commitLoading = false
      } finally {
        this.commitLoading = false
        this.queryClick()
      }
    },

    // 发票管理列表数据查询
    async queryClick(type) {
      try {
        this.queryLoading = true
        type && type === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await invoiceOpenPage(this.queryPageParams)
        if (code === 0) {
          this.tableData = records
          console.log(this.tableData)
          this.pager = pager
        }
      } finally {
        this.queryLoading = false
      }
    },
    // 重置查询条件
    resetClick() {
      this.form = this.$options.data().form
      this.queryClick(1)
    },
    // 点击开票等级按钮跳转至开票登记页
    async showPayReg() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$router.push({
            path: 'added',
            append: 'true',
            query: { id: this.form.vendorId }
          })
        } else {
          this.$message.warning('请选择供应商')
        }
      })
    },
    // 获取供应商信息
    async _queryVendorList() {
      const { datas } = await queryVendorList({ vendorType: 4 })
      this.vendorOptions = datas
    },
    // 分页方法
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    async uploadFile() {
      try {
        const forms = new FormData()
        forms.append('file', this.file)
        const { code, datas } = await invoicePreview(forms)
        if (code === 0) {
          this.importData = datas
          this.dialogVisibleImport = true
        }
        // 隐藏弹窗清空数据
        this.file = ''
        this.fileList = []
      } catch (err) {
        console.log(err)
      } finally {
        // loadingInstance.close()
      }
    },
    beforeAvatarUpload(file) {
      const name = file.name.split('.')
      if (name[name.length - 1] === 'xlsx' || name[name.length - 1] === 'xls') {
        return true
      } else {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: '只能上传 xlsx 或 xls的文件'
        })
        // return false
      }
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.fileList.length !== 0) {
        if (this.beforeAvatarUpload(file)) {
          this.uploadFile()
        }
      } else {
        this.importDialog = false
      }
    },

    getTotal(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t('page.total')
          return
        }
        if (column.property === 'invoiceAmount') {
          const values = data.map(item => Number(item[column.property])) ? data.map(item => Number(item[column.property])) : []
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }

}
</script>

<style scoped lang="scss">
.date {
  /deep/.el-range-input {
    width: 200px !important;
  }
  .invoice {
    display: flex;
  }
 /deep/.el-table .tr-red {
    color: red !important;
  }
}

ul{
   border: 1px solid #999;
  border-radius: 2px;
  height: 160px;
  list-style: none;
  padding: 0;
  overflow-y: auto;
  // cursor: pointer;

}
li{
  list-style: none;
  margin: 5px;
  white-space: nowrap;
	padding: 0 8px;
  cursor:pointer;

}
.active{
color:#fff;
background-color:#169BD5;
}
.invoiceActive{
color:#fff;
background-color:#169BD5;
}
</style>
